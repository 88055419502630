/* Table of Contents
--------------------------------------------------------------
    /* Base      
    /* Typoghraphy
    /* Helpers    
    /* Header    
    /* Footer    
    /* Buttons
    /* Forms    
    /* Pages    
    /* Responsive    
--------------------------------------------------------------
*/

h1 {
  font-size: 46px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

h2 {
  font-size: 40px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}
p:not(:last-of-type) {
  padding-bottom: 30px;
}

h1,
h2,
p {
  padding: 0;
  margin: 0;
  clear: both;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Helpers Style
-------------------------------------------------------------- */
.no-pd {
  padding: 0 !important;
}

.no-mg {
  margin: 0 !important;
}

.mg-top-200 {
  margin-top: 200px;
}

.mg-top-160 {
  margin-top: 160px;
}

.mg-top-140 {
  margin-top: 140px;
}

.mg-top-130 {
  margin-top: 130px;
}

.mg-top-120 {
  margin-top: 120px;
}

.mg-top-110 {
  margin-top: 110px;
}

.mg-top-100 {
  margin-top: 100px;
}

.mg-top-80 {
  margin-top: 80px;
}

.mg-top-60 {
  margin-top: 60px;
}

.mg-top-50 {
  margin-top: 50px;
}

.link,
.link-inside {
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
}
.link::before,
.link::after,
.link-inside::before,
.link-inside::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
  background-color: currentColor;
  opacity: 1;
}
.link::before,
.link-inside::before {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.link::after,
.link-inside::after {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}

.link:hover::before {
  -webkit-transform: translateX(300%);
  transform: translateX(300%);
}
.link:hover::after {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

/* Header Style
-------------------------------------------------------------- */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}
.header-wrap {
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
}
.header-menu ul li a {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-menu ul li a:hover {
  color: #bf1304;
}
.header-menu ul li.active a {
  color: #bf1304;
  font-weight: 600;
}
.header-menu ul li.mobile {
  display: none;
}
.header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
}
.header-button .button {
  min-width: 200px;
  padding: 17px 16px 15px;
}
.header-button-mobile {
  display: none;
}
.header.sticky {
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.04);
}
.header.sticky .header-wrap {
  padding: 15px 0;
}

.headroom {
  -webkit-transition: -webkit-transform 0.5s
    cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: -webkit-transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06),
    -webkit-transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  will-change: transform;
}
.headroom.headroom--pinned {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}
.headroom.headroom--unpinned {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* Footer Style
-------------------------------------------------------------- */
.footer {
  position: relative;
  width: 100%;
  float: left;
  background-color: #000000;
}
.footer-top {
  padding: 64px 0;
}
.footer-center {
  padding: 40px 0 64px;
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
}
.footer-bottom {
  padding: 32px 0 40px;
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
}
.footer-bottom-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer-bottom-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-logo {
  padding-right: 40px;
  margin-right: 40px;
  border-right: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
}
.footer-content {
  margin-top: 40px;
}
.footer-content p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: rgba(255, 255, 255, 0.5);
}
.footer-menu span {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 25px;
}
.footer-menu ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-menu ul li:not(:last-child) {
  margin-bottom: 25px;
}
.footer-menu ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #d0d9dd;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.footer-menu ul li a svg {
  height: 22px;
  width: 22px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 22px;
  flex: 0 0 22px;
  fill: #d0d9dd;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.footer-menu ul li a:hover {
  color: #ffffff;
}
.footer-menu ul li a:hover svg {
  fill: #ffffff;
}
.footer-menu ul li a:not([href]) {
  cursor: default;
  color: #d0d9dd;
}
.footer-menu ul li a:not([href]):hover {
  color: #ffffff;
}
.footer-links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 32px;
}
.footer-links ul li a {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  color: #ffffff;
}
.footer-social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}
.footer-social ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.footer-social ul li a svg {
  height: 24px;
  width: 24px;
  fill: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.footer-social ul li a:hover svg {
  fill: #ffffff;
}

/* General Style
-------------------------------------------------------------- */
.main {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  overflow: hidden;
  padding-bottom: 140px;
}

.dchf-container {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .dchf-container {
    max-width: 570px;
  }
}
@media (min-width: 768px) {
  .dchf-container {
    max-width: 760px;
  }
}
@media (min-width: 992px) {
  .dchf-container {
    max-width: 990px;
  }
}
@media (min-width: 1200px) {
  .dchf-container {
    max-width: 1190px;
  }

  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .dchf-container {
    max-width: 1280px;
  }

  .container {
    max-width: 1170px;
  }
}
.main-title {
  margin-bottom: 40px;
}
.main-title h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 40px;
}
.main-title p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}

.dchf-page-hero {
  position: relative;
  width: 100%;
  float: left;
  padding: 230px 0 0;
}
.dchf-page-hero-content {
  padding-top: 12px;
}
.dchf-page-hero-content h1 {
  font-size: 66px;
  font-weight: bold;
  line-height: normal;
  color: #000000;
  margin-bottom: 40px;
}
.dchf-page-hero-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-page-hero-buttons {
  margin-top: 60px;
}

.clouds {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.clouds .cloud-1 {
  position: absolute;
  top: 0;
  -webkit-animation: moveclouds 70s linear infinite;
  animation: moveclouds 70s linear infinite;
}
.clouds .cloud-2 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: moveclouds 35s linear infinite;
  animation: moveclouds 35s linear infinite;
}
.clouds .cloud-3 {
  position: absolute;
  bottom: 0px;
  -webkit-animation: moveclouds 60s linear infinite;
  animation: moveclouds 60s linear infinite;
}

/* Buttons Style
-------------------------------------------------------------- */
.main-menu-indicator {
  position: relative;
  display: none;
  border: none;
  padding: 0;
  height: 30px;
  width: 30px;
  background: transparent;
  outline: 0;
  z-index: 99;
  cursor: pointer;
}
.main-menu-indicator:hover {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.main-menu-indicator > span {
  -webkit-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  background: #000000;
}
.main-menu-indicator > span::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000000;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.main-menu-indicator > span::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000000;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.main-menu-indicator > span::before {
  bottom: -10px;
}
.main-menu-indicator.is-open > span {
  background: transparent;
}
.main-menu-indicator.is-open > span::after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background: #ffffff;
}
.main-menu-indicator.is-open > span::before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background: #ffffff;
}
.main-menu-indicator:focus {
  outline: none;
}

.button {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 220px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  padding: 16px;
  border-radius: 28px;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  outline: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 28px;
  z-index: -2;
}
.button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 28px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}
.button svg {
  height: 16px;
  width: 16px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.button:hover {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.button:hover svg {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.button:hover::before {
  width: 100%;
}
.button-primary {
  color: #ffffff;
}
.button-primary::after {
  background-color: #bf1304;
}
.button-primary::before {
  background-color: #ffffff;
}
.button-primary svg {
  fill: #ffffff;
}
.button-primary:hover {
  border-color: #bf1304;
  color: #bf1304;
}
.button-primary:hover svg {
  fill: #bf1304;
}
.button-primary-alt {
  color: #bf1304;
}
.button-primary-alt::after {
  background-color: rgba(191, 19, 4, 0.05);
}
.button-primary-alt::before {
  background-color: #bf1304;
}
.button-primary-alt svg {
  fill: #bf1304;
}
.button-primary-alt:hover {
  color: #ffffff;
}
.button-primary-alt:hover svg {
  fill: #ffffff;
}
.button-white {
  color: #000000;
}
.button-white::after {
  background-color: #ffffff;
}
.button-white::before {
  background-color: #000000;
}
.button-white svg {
  fill: #000000;
}
.button-white:hover {
  border-color: #000000;
  color: #ffffff;
}
.button-white:hover svg {
  fill: #ffffff;
}
.button-black {
  color: #ffffff;
}
.button-black::after {
  background-color: #000000;
}
.button-black::before {
  background-color: #ffffff;
}
.button-black svg {
  fill: #ffffff;
}
.button-black:hover {
  border-color: #000000;
  color: #000000;
}
.button-black:hover svg {
  fill: #000000;
}

.play-button svg {
  height: 24px;
  width: 24px;
}

/* Form Style
-------------------------------------------------------------- */
.dchf-newsletter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 24px;
}
.dchf-newsletter h2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #ffffff;
}
.dchf-newsletter-input {
  position: relative;
  max-width: 370px;
}
.dchf-newsletter-input .email {
  height: 50px;
  width: 100%;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  padding: 0px 130px 0px 24px;
  border: 1px solid transparent;
}
.dchf-newsletter-input .email::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}
.dchf-newsletter-input .email::-moz-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}
.dchf-newsletter-input .email:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}
.dchf-newsletter-input .email::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}
.dchf-newsletter-input .email::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}
.dchf-newsletter-input .email:focus {
  outline: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.dchf-newsletter-input .send {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 120px;
  border-radius: 24px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #bf1304;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
}
.dchf-newsletter-input .send:hover {
  background-color: #8d0e03;
}

.info {
  cursor: pointer;
}
.info svg {
  height: 13px;
  width: 13px;
  fill: #000000;
}
.info.alt svg {
  fill: #ffffff;
}

.tooltip-inner {
  padding: 8px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 10px;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
}
.tooltip-arrow::before {
  display: none;
}

/* Modal Style
-------------------------------------------------------------- */
/* Animation Style
-------------------------------------------------------------- */
@-webkit-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
@keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
/* Discover Page Style
-------------------------------------------------------------- */
.hero {
  position: relative;
  width: 100%;
  float: left;
  padding: 230px 0 165px;
}
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 840px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  opacity: 0.5;
  z-index: -1;
}
.hero-background::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 180px;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgb(250, 250, 250)),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(
    0deg,
    rgb(250, 250, 250) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.hero-content h1 {
  font-size: 66px;
  font-weight: 800;
  line-height: 1.15;
  color: #000000;
  margin-bottom: 24px;
}
.hero-content h1 span {
  color: #bf1304;
}
.hero-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.hero-content .img-mobile {
  display: none;
}
.hero-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
  margin-top: 80px;
}
.hero-buttons .button-white {
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.04);
}
.hero-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.dchf-stats {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-stats-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 16px;
  padding: 64px;
  border: 1px solid #e7e7e7;
  border-radius: 23px;
  background-color: #ffffff;
}
.dchf-stats-item h1 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #7f7f7f;
  margin-bottom: 8px;
}
.dchf-stats-item p {
  position: relative;
  font-size: 32px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
}
.dchf-stats-item p span {
  font-weight: 300;
}
.dchf-stats-item .coming-soon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
.dchf-stats-item .coming-soon p::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgb(255, 255, 255)),
    to(rgba(255, 255, 255, 0.8))
  );
  background-image: linear-gradient(
    -90deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
}

.dchf-about {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-about-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 40px;
}
.dchf-about-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-about-content .button {
  margin-top: 60px;
}
.dchf-about-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dchf-impression {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-impression-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
}
.dchf-impression-wrap .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-height: 110px;
  padding: 0 24px;
  border-radius: 23px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
}
.dchf-impression-wrap .item h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
}

.dchf-for {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-for-content {
  padding-top: 70px;
}
.dchf-for-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 40px;
}
.dchf-for-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-for-items {
  position: relative;
}
.dchf-for-items-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 160px;
  border-radius: 13px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #e7e7e7;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
}
.dchf-for-items-wrap.first {
  margin-top: 20px;
}
.dchf-for-items h1 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: #000000;
  margin-top: 20px;
}
.dchf-for-items-shape {
  position: absolute;
  z-index: -1;
}
.dchf-for-items-shape.circle-1 {
  left: 20px;
  top: -20px;
}
.dchf-for-items-shape.circle-2 {
  right: 20px;
  top: -20px;
}
.dchf-for-items-shape.circle-3 {
  right: 20px;
  bottom: -20px;
}
.dchf-for-items-shape.circle-4 {
  right: 20px;
  bottom: -20px;
}

.dchf-works {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-works-wrap {
  background-color: #ffffff;
  padding: 80px 0 64px;
  border-radius: 23px;
  border: 1px solid #e7e7e7;
}
.dchf-works-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 40px;
}
.dchf-works-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-works-img {
  margin-top: -140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.dchf-works-item-wrap {
  position: relative;
}
.dchf-works-item-wrap:first-of-type::before {
  content: "";
  position: absolute;
  right: 0;
  top: -10px;
  background-image: URL('/public/img/shapes/arrow-curve.svg');
  background-repeat: no-repeat;
  background-position: right;
  width: 264px;
  height: 54px;
}
.dchf-works-item-wrap:nth-child(2)::before {
  content: "";
  position: absolute;
  right: 0;
  top: 40px;
  background-image: URL('/public/img/shapes/arrow-curve-alt.svg');
  background-repeat: no-repeat;
  background-position: right;
  width: 264px;
  height: 54px;
}
.dchf-works-item-wrap.alt:first-of-type::before {
  right: -130px;
}
.dchf-works-item-wrap.alt:nth-child(2)::before {
  right: -130px;
}
.dchf-works-item .icon {
  position: relative;
  height: 90px;
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.dchf-works-item .icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-image: URL('/public/img/shapes/circle.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.dchf-works-item .icon img {
  position: relative;
}
.dchf-works-item .content {
  margin-top: 32px;
}
.dchf-works-item .content h1 {
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 16px;
}
.dchf-works-item .content p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #000000;
}
.dchf-works-item.alt .icon {
  margin: 0 auto;
}
.dchf-works-item.alt .content {
  text-align: center;
}

.dchf-partners {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-partners-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 130px;
  border-radius: 23px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
}
.dchf-partners-item img {
  max-width: 100%;
}

.dchf-news {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-news-wrap {
  position: relative;
}
.dchf-news-item {
  position: relative;
}
.dchf-news-item-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.dchf-news-img {
  height: 280px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
.dchf-news-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.dchf-news-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 20px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #e7e7e7;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.dchf-news-content h1 {
  font-size: 26px;
  font-weight: 800;
  line-height: 1.31;
  color: #000000;
  margin-bottom: 10px;
}
.dchf-news-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 30px;
}
.dchf-news-content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #bf1304;
  margin-top: auto;
}
.dchf-news-content a svg {
  height: 16px;
  width: 16px;
  fill: #bf1304;
}
.dchf-news-content a:hover .link-inside::before {
  -webkit-transform: translateX(300%);
  transform: translateX(300%);
}
.dchf-news-content a:hover .link-inside::after {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.dchf-news-shape {
  position: absolute;
  bottom: -30px;
  left: -80px;
  z-index: -1;
}

/* About Page Style
-------------------------------------------------------------- */
.dchf-about-hero {
  padding: 175px 0 0;
}
.dchf-about-hero .dchf-page-hero-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.dchf-about-hero .dchf-page-hero-content {
  padding-top: 60px;
}

.dchf-about-info {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-about-info-content {
  text-align: center;
}
.dchf-about-info-content p {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.67;
  color: #000000;
}
.dchf-about-info-content p span {
  font-weight: 500;
}

.dchf-benefits {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-benefits-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 70px;
  margin-right: -25px;
  margin-left: -25px;
}
.dchf-benefits-wrap::before {
  content: "";
  top: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: URL('/public/img/shapes/shape-line.svg');
  height: 1012px;
  width: 937px;
  z-index: 1;
}
.dchf-benefits-wrap .item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
  min-height: 290px;
  padding-left: 25px;
  padding-right: 25px;
}
.dchf-benefits-wrap .item-wrap {
  height: 100%;
  border-radius: 23px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  padding: 40px;
}
.dchf-benefits-wrap .item-wrap img {
  margin-bottom: 24px;
}
.dchf-benefits-wrap .item-wrap h1 {
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  color: #000000;
  margin-bottom: 20px;
}
.dchf-benefits-wrap .item-wrap p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-benefits-button {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dchf-benefits-button .button {
  width: 400px;
  max-width: 100%;
}

.dchf-about-stats {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-about-stats-wrap {
  position: relative;
}
.dchf-about-stats-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  width: 1320px;
  height: 384px;
  background-image: URL('/public/img/shapes/content-bg-red.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  z-index: -1;
}
.dchf-about-stats-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 80px;
}
.dchf-about-stats-items-wrap {
  padding: 60px 0;
}
.dchf-about-stats-items .item h1 {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
}
.dchf-about-stats-items .item p {
  position: relative;
  font-size: 36px;
  font-weight: 800;
  line-height: normal;
  color: #ffffff;
}
.dchf-about-stats-items .item .coming-soon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
.dchf-about-stats-items .item .coming-soon p::before {
  content: "";
  position: absolute;
  height: 100%;
  width: calc(100% + 10px);
  top: 0;
  left: -5px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgb(191, 19, 4)),
    to(rgba(191, 19, 4, 0.5))
  );
  background-image: linear-gradient(
    -90deg,
    rgb(191, 19, 4) 0%,
    rgba(191, 19, 4, 0.5) 100%
  );
}
.dchf-about-stats-content {
  margin-top: 80px;
}
.dchf-about-stats-content p {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.38;
  color: #ffffff;
}
.dchf-about-stats-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.dchf-about-stats-img img {
  width: 320px;
}

.dchf-for-page {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-for-page-wrap {
  margin-top: 30px;
}
.dchf-for-page-item {
  position: relative;
  height: 100%;
  text-align: center;
  padding: 50px 15px 24px;
  border: 1px solid #e7e7e7;
  border-radius: 13px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.7);
}
.dchf-for-page-item-wrap {
  position: relative;
}
.dchf-for-page-item img {
  margin-bottom: 20px;
}
.dchf-for-page-item h1 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: #000000;
  margin-bottom: 30px;
}
.dchf-for-page-item p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #000000;
}
.dchf-for-page .shape {
  position: absolute;
  z-index: -1;
}
.dchf-for-page .shape img {
  width: 74px;
  height: 74px;
}
.dchf-for-page .shape.circle-1 {
  left: -30px;
  bottom: -10px;
}
.dchf-for-page .shape.circle-2 {
  right: 20px;
  top: -20px;
}
.dchf-for-page .shape.circle-3 {
  bottom: -20px;
  left: 30px;
}
.dchf-for-page .shape.circle-4 {
  right: 20px;
  top: -20px;
}
.dchf-for-page-button {
  text-align: center;
  margin-top: 60px;
}
.dchf-for-page-button .button {
  width: 400px;
  max-width: 100%;
}

/* Works Page Style
-------------------------------------------------------------- */
.dchf-works-hero {
  padding: 175px 0 0;
}
.dchf-works-hero .dchf-page-hero-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.dchf-works-hero .dchf-page-hero-content {
  padding-top: 60px;
}

.dchf-works-info {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-works-info-content {
  text-align: center;
}
.dchf-works-info-content p {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.67;
  color: #000000;
}
.dchf-works-info-content p span {
  font-weight: 500;
}

.dchf-content-box {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-content-box-wrap {
  position: relative;
  padding: 40px;
  border: 1px solid #e7e7e7;
  border-radius: 23px;
  background-color: #ffffff;
}
.dchf-content-box-wrap .content {
  text-align: center;
}
.dchf-content-box-wrap .content h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 24px;
}
.dchf-content-box-wrap .content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}

.dchf-works-about {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-works-about-row {
  margin-top: 110px;
}
.dchf-works-about-content {
  padding-top: 70px;
}
.dchf-works-about-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
}
.dchf-works-about-content h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
  margin-top: 16px;
}
.dchf-works-about-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-works-about-content p:first-of-type {
  margin-top: 40px;
}
.dchf-works-about-content p span {
  font-weight: 500;
}

.dchf-works-moneta {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-works-moneta-content {
  text-align: center;
  margin-bottom: 64px;
}
.dchf-works-moneta-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 16px;
}
.dchf-works-moneta-content h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 24px;
}
.dchf-works-moneta-content p {
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.7);
}
.dchf-works-moneta-img {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dchf-works-moneta-button {
  margin-top: 24px;
  text-align: center;
}
.dchf-works-moneta-button .button {
  width: 300px;
  max-width: 100%;
}

.dchf-use-case {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-use-case-title {
  margin-bottom: 40px;
  text-align: center;
}
.dchf-use-case-title h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
}
.dchf-use-case .item {
  position: relative;
  height: 100%;
  z-index: 1;
}
.dchf-use-case .item-wrap {
  position: relative;
  height: 100%;
  padding: 40px;
  border-radius: 23px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #e7e7e7;
  background-color: rgba(255, 255, 255, 0.7);
}
.dchf-use-case .item .icon {
  text-align: center;
  margin-bottom: 40px;
}
.dchf-use-case .item .icon img {
  width: 108px;
  height: 108px;
}
.dchf-use-case .item .content {
  text-align: center;
  padding: 0 40px;
}
.dchf-use-case .item .content h1 {
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 16px;
}
.dchf-use-case .item .content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-use-case-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.dchf-use-case-shape img {
  height: 74px;
  width: 74px;
  z-index: -1;
}

/* Story Page Style
-------------------------------------------------------------- */
.dchf-story-info {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-story-info-content {
  text-align: center;
}
.dchf-story-info-content p {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.67;
  color: #000000;
}

.dchf-story-about {
  position: relative;
  width: 100%;
  float: left;
  padding: 130px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.dchf-story-about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(rgb(250, 250, 250))
  );
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(250, 250, 250) 100%
  );
  z-index: -2;
}
.dchf-story-about-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
}
.dchf-story-about-bg img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.dchf-story-about-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 32px;
}
.dchf-story-about-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-story-about-content .button {
  width: 300px;
  max-width: 100%;
  margin-top: 64px;
}

.dchf-story-work {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-story-work-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 32px;
}
.dchf-story-work-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}

.dchf-story-work-alt {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-story-work-alt-wrap {
  position: relative;
  padding-bottom: 60px;
}
.dchf-story-work-alt-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  width: 1320px;
  height: 460px;
  background-image: URL('/public/img/shapes/content-bg-red-alt.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  z-index: -1;
}
.dchf-story-work-alt-content {
  padding-top: 120px;
}
.dchf-story-work-alt-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 32px;
}
.dchf-story-work-alt-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #ffffff;
}

/* Page Style
-------------------------------------------------------------- */
.page-content {
  position: relative;
  width: 100%;
  float: left;
  padding-top: 170px;
}
.page-content-wrap {
  border-radius: 23px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #e7e7e7;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 80px 0;
}
.page-content-text h1 {
  font-size: 66px;
  font-weight: 800;
  line-height: 1.15;
  color: #000000;
  margin-bottom: 80px;
}
.page-content-text h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: #000000;
  margin-top: 30px;
}
.page-content-text p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
  padding-top: 30px;
  word-break: break-word;
}
.page-content-text p:not(:last-of-type) {
  padding-bottom: 0px;
}
.page-content-text ul {
  margin-top: 30px;
}
.page-content-text ul li {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.page-content-text a {
  color: #bf1304;
}

/* Moneta Page Style
-------------------------------------------------------------- */
.dchf-moneta-container {
  position: relative;
}
.dchf-moneta-bg {
  margin-top: -120px;
}

.dchf-moneta-slider {
  position: relative;
  width: 100%;
  float: left;
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
}
.dchf-moneta-slider .item {
  width: 185px;
  padding: 13px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  margin: 0 3px;
}
.dchf-moneta-slider .item h1 {
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  color: #000000;
}

.dchf-moneta-info {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-moneta-info-content {
  text-align: center;
}
.dchf-moneta-info-content p {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.67;
  color: #000000;
}

.dchf-moneta-about {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-moneta-about-wrap {
  position: relative;
  height: 360px;
}
.dchf-moneta-about-wrap .item {
  position: relative;
  border: 1px solid #d0d9dd;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.dchf-moneta-about-wrap .item h1 {
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 8px;
}
.dchf-moneta-about-wrap .item h2 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #7f7f7f;
  margin-bottom: 8px;
}
.dchf-moneta-about-wrap .item p {
  position: relative;
  font-size: 28px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
}
.dchf-moneta-about-wrap .item .coming-soon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
}
.dchf-moneta-about-wrap .item .coming-soon p::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgb(255, 255, 255)),
    to(rgba(255, 255, 255, 0.8))
  );
  background-image: linear-gradient(
    -90deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
}
.dchf-moneta-about-wrap .item .shape {
  position: absolute;
  z-index: -1;
}
.dchf-moneta-about-wrap .item.large {
  width: 205px;
  height: 240px;
  padding: 8px;
  text-align: center;
}
.dchf-moneta-about-wrap .item.small {
  width: 95px;
  height: 95px;
  padding: 4px;
  text-align: center;
}
.dchf-moneta-about-wrap .item.first {
  position: absolute;
  top: -35px;
  left: 10px;
}
.dchf-moneta-about-wrap .item.first .shape {
  left: -1px;
  top: -16px;
}
.dchf-moneta-about-wrap .item.second {
  position: absolute;
  top: -55px;
  right: 64px;
}
.dchf-moneta-about-wrap .item.second .shape {
  top: -1px;
  left: -23px;
}
.dchf-moneta-about-wrap .item.third {
  position: absolute;
  bottom: -62px;
  left: 32px;
}
.dchf-moneta-about-wrap .item.third .shape {
  top: -1px;
  left: -24px;
}
.dchf-moneta-about-wrap .item.fourth {
  position: absolute;
  bottom: -57px;
  right: 0;
}
.dchf-moneta-about-wrap .item.fourth .shape {
  top: -1px;
  right: -1px;
}
.dchf-moneta-about-wrap .grid img {
  max-width: 100%;
}
.dchf-moneta-about-wrap-alt {
  position: relative;
  height: 501px;
}
.dchf-moneta-about-wrap-alt .item {
  position: relative;
  border: 1px solid #d0d9dd;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dchf-moneta-about-wrap-alt .item h1 {
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 8px;
}
.dchf-moneta-about-wrap-alt .item h2 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #7f7f7f;
  margin-bottom: 8px;
  margin-top: 16px;
}
.dchf-moneta-about-wrap-alt .item p {
  font-size: 26px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
}
.dchf-moneta-about-wrap-alt .item .shape {
  position: absolute;
  z-index: -1;
}
.dchf-moneta-about-wrap-alt .item.large {
  width: 213px;
  height: 210px;
  padding: 8px;
  text-align: center;
}
.dchf-moneta-about-wrap-alt .item.small {
  width: 110px;
  height: 110px;
  padding: 4px;
  text-align: center;
}
.dchf-moneta-about-wrap-alt .item.first {
  position: absolute;
  top: 70px;
  left: 66px;
}
.dchf-moneta-about-wrap-alt .item.first .shape {
  left: -2px;
  top: -18px;
}
.dchf-moneta-about-wrap-alt .item.second {
  position: absolute;
  top: 4px;
  left: 336px;
}
.dchf-moneta-about-wrap-alt .item.second .shape {
  top: -24px;
  left: -84px;
}
.dchf-moneta-about-wrap-alt .item.third {
  position: absolute;
  bottom: 3px;
  left: -44px;
}
.dchf-moneta-about-wrap-alt .item.third .shape {
  top: -38px;
  left: -1px;
}
.dchf-moneta-about-wrap-alt .item.fourth {
  position: absolute;
  bottom: 71px;
  left: 329px;
}
.dchf-moneta-about-wrap-alt .item.fourth .shape {
  bottom: -16px;
  right: 0;
}
.dchf-moneta-about-wrap-alt .grid img {
  max-width: 100%;
}
.dchf-moneta-about-content {
  padding-top: 40px;
}
.dchf-moneta-about-content.alt {
  padding-top: 80px;
}
.dchf-moneta-about-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 32px;
}
.dchf-moneta-about-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-moneta-about-content .button {
  max-width: 100%;
  width: 220px;
  margin-top: 80px;
}

.dchf-moneta-services {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-moneta-services .item {
  position: relative;
  height: 100%;
  text-align: center;
  padding: 40px 30px 20px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7);
}
.dchf-moneta-services .item .icon {
  height: 108px;
  width: 108px;
  margin: 0 auto;
}
.dchf-moneta-services .item .content {
  margin-top: 40px;
}
.dchf-moneta-services .item .content h1 {
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 16px;
}
.dchf-moneta-services .item .content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-moneta-services .item .content .button {
  margin-top: 40px;
  max-width: 100%;
}

.dchf-moneta-card {
  position: relative;
  width: 100%;
  float: left;
}
.dchf-moneta-card-content {
  padding-top: 40px;
}
.dchf-moneta-card-content h1 {
  font-size: 46px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
  margin-bottom: 32px;
}
.dchf-moneta-card-content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}
.dchf-moneta-card-content ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #000000;
  margin-top: 26px;
}
.dchf-moneta-card-content ul li .icon {
  width: 20px;
  height: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #bf1304;
  border-radius: 50px;
}
.dchf-moneta-card-content ul li .icon svg {
  width: 10px;
  height: 8px;
  fill: #ffffff;
}

/* Responsive Style
-------------------------------------------------------------- */
@media (max-width: 1199px) {
  .header-wrap {
    padding: 20px 0;
  }

  .main-title h1 {
    font-size: 36px;
  }

  .hero {
    padding: 200px 0 140px;
  }
  .hero-content h1 {
    font-size: 46px;
  }

  .dchf-page-hero {
    padding: 200px 0 0;
  }
  .dchf-page-hero-content h1 {
    font-size: 46px;
  }

  .dchf-impression-wrap .item h1 {
    font-size: 20px;
  }

  .dchf-about-hero {
    padding: 175px 0 0;
  }
  .dchf-about-content h1 {
    font-size: 36px;
  }

  .dchf-for-content {
    padding-top: 40px;
  }
  .dchf-for-content h1 {
    font-size: 36px;
  }

  .dchf-works-hero {
    padding: 175px 0 0;
  }
  .dchf-works-content h1 {
    font-size: 36px;
  }
  .dchf-works-item-wrap:first-of-type::before {
    width: 200px;
  }
  .dchf-works-item-wrap:nth-child(2)::before {
    width: 200px;
  }
  .dchf-works-item-wrap.alt:first-of-type::before {
    right: -100px;
  }
  .dchf-works-item-wrap.alt:nth-child(2)::before {
    right: -100px;
  }

  .dchf-news-content h1 {
    font-size: 20px;
  }

  .dchf-benefits-wrap .item-wrap {
    padding: 30px;
  }

  .dchf-about-stats-items {
    gap: 60px;
  }
  .dchf-about-stats-items .item p {
    font-size: 26px;
  }
  .dchf-about-stats-content {
    margin-top: 40px;
  }
  .dchf-about-stats-content p {
    font-size: 20px;
  }

  .dchf-for-page-item {
    padding: 30px 15px;
  }
  .dchf-for-page-item-wrap {
    margin-bottom: 30px;
  }
  .dchf-for-page-button {
    margin-top: 30px;
  }

  .dchf-works-about-content h1 {
    font-size: 36px;
  }

  .dchf-story-about-content h1 {
    font-size: 36px;
  }

  .dchf-story-work-content h1 {
    font-size: 36px;
  }

  .dchf-story-work-alt-content {
    padding-top: 80px;
  }
  .dchf-story-work-alt-content h1 {
    font-size: 36px;
  }

  .dchf-moneta-bg {
    margin-top: 0px;
  }

  .dchf-moneta-about-content h1 {
    font-size: 36px;
  }

  .dchf-works-moneta-content h1 {
    font-size: 36px;
  }

  .dchf-moneta-card-content h1 {
    font-size: 36px;
  }

  .page-content-wrap {
    padding: 40px 0;
  }
  .page-content-text h1 {
    font-size: 36px;
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) {
  .header-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #0f0f0f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    padding-top: 100px;
    margin-top: 0;
    -webkit-transform: translatex(100%);
    transform: translatex(100%);
    z-index: 99;
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  }
  .header-menu.is-open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .header-menu ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }
  .header-menu ul li {
    display: block;
    text-align: center;
  }
  .header-menu ul li a:not(.button) {
    font-size: 24px;
    color: #ffffff;
  }
  .header-menu ul li.active a {
    color: #bf1304;
  }

  .main-menu-indicator {
    display: block;
  }

  .toggle-open {
    overflow: hidden;
  }

  .main {
    padding-bottom: 60px;
  }

  .mg-top-200 {
    margin-top: 100px;
  }

  .mg-top-160 {
    margin-top: 70px;
  }

  .mg-top-140 {
    margin-top: 70px;
  }

  .mg-top-120 {
    margin-top: 70px;
  }

  .hero {
    padding: 150px 0 140px;
  }
  .hero-content h1 {
    font-size: 36px;
  }
  .hero-buttons {
    margin-top: 30px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dchf-page-hero {
    padding: 150px 0 0;
  }
  .dchf-page-hero-content h1 {
    font-size: 36px;
  }

  .dchf-stats-wrap {
    padding: 24px;
  }
  .dchf-stats-item p {
    font-size: 24px;
  }

  .dchf-impression-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .dchf-impression-wrap .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
  }

  .dchf-for-content {
    padding-top: 0px;
    margin-bottom: 40px;
  }

  .dchf-works-hero .dchf-page-hero-img {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0px;
  }
  .dchf-works-hero .dchf-page-hero-img img {
    max-width: 100%;
  }
  .dchf-works-hero .dchf-page-hero-content {
    padding-top: 0px;
  }
  .dchf-works-item {
    text-align: center;
  }
  .dchf-works-item .icon {
    margin: 0 auto;
  }
  .dchf-works-item-wrap:first-of-type::before {
    content: none;
  }
  .dchf-works-item-wrap:nth-child(2)::before {
    content: none;
  }

  .dchf-partners-item {
    margin-bottom: 30px;
  }

  .dchf-news-item {
    margin-bottom: 30px;
  }

  .dchf-about-hero .dchf-page-hero-img {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0px;
  }
  .dchf-about-hero .dchf-page-hero-img img {
    max-width: 100%;
  }
  .dchf-about-hero .dchf-page-hero-content {
    padding-top: 0px;
  }
  .dchf-about-info-content p {
    font-size: 20px;
  }

  .dchf-benefits-wrap {
    row-gap: 30px;
    margin-right: -15px;
    margin-left: -15px;
  }
  .dchf-benefits-wrap::before {
    content: none;
  }
  .dchf-benefits-wrap .item {
    padding-left: 15px;
    padding-right: 15px;
  }

  .dchf-about-stats-wrap {
    padding: 40px 0;
  }
  .dchf-about-stats-wrap::before {
    background-size: cover;
  }
  .dchf-about-stats-items {
    gap: 30px;
  }

  .dchf-works-info-content p {
    font-size: 20px;
  }

  .dchf-content-box-wrap {
    padding: 30px;
  }
  .dchf-content-box-wrap .content h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .dchf-works-about-content {
    padding-top: 0px;
  }

  .dchf-use-case .item-wrap {
    padding: 30px 15px;
  }
  .dchf-use-case .item .content {
    padding: 0px;
  }

  .dchf-story-info-content p {
    font-size: 20px;
  }

  .dchf-story-about {
    padding: 60px 0;
  }

  .dchf-moneta-info-content p {
    font-size: 20px;
  }

  .dchf-moneta-about-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .dchf-moneta-about-content {
    text-align: center;
    padding-top: 0px;
    margin-bottom: 60px;
  }
  .dchf-moneta-about-content .button {
    margin-top: 30px;
  }
  .dchf-moneta-about-content.alt {
    padding-top: 0px;
  }
  .dchf-moneta-about-wrap .item p {
    font-size: 24px;
  }
  .dchf-moneta-about-wrap .item.large {
    width: 145px;
    height: 160px;
  }
  .dchf-moneta-about-wrap .item.first {
    top: 20px;
    left: 70px;
  }
  .dchf-moneta-about-wrap .item.first .shape {
    width: 200px;
    height: 169px;
    top: -10px;
  }
  .dchf-moneta-about-wrap .item.second {
    top: 0;
    right: 100px;
  }
  .dchf-moneta-about-wrap .item.third {
    bottom: 0;
    left: 80px;
  }
  .dchf-moneta-about-wrap .item.fourth {
    bottom: 0;
    right: 60px;
  }
  .dchf-moneta-about-wrap .item.fourth .shape {
    width: 200px;
    height: 170px;
  }
  .dchf-moneta-about-wrap .grid {
    text-align: center;
  }
  .dchf-moneta-about-wrap-alt .item.large {
    width: 185px;
    height: 170px;
  }
  .dchf-moneta-about-wrap-alt .item.first {
    left: 163px;
  }
  .dchf-moneta-about-wrap-alt .item.second {
    top: 50px;
    left: auto;
    right: 88px;
  }
  .dchf-moneta-about-wrap-alt .item.second .shape {
    top: -19px;
    left: -69px;
    height: 217px;
    width: 244px;
  }
  .dchf-moneta-about-wrap-alt .item.third {
    bottom: 48px;
    left: 93px;
  }
  .dchf-moneta-about-wrap-alt .item.third .shape {
    width: 257px;
    height: 220px;
    top: -32px;
  }
  .dchf-moneta-about-wrap-alt .item.fourth {
    left: auto;
    right: 157px;
    bottom: 70px;
  }
  .dchf-moneta-about-wrap-alt .grid {
    text-align: center;
  }

  .dchf-moneta-services .item {
    padding: 20px;
  }
  .dchf-moneta-services .item .icon {
    height: 80px;
    width: 80px;
  }
  .dchf-moneta-services .item .icon img {
    max-width: 100%;
  }
  .dchf-moneta-services .item .content .button {
    min-width: 100%;
  }

  .dchf-moneta-card-content {
    padding-top: 0px;
  }

  .footer-content {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .header-menu ul li.mobile {
    display: block;
  }
  .header-button {
    display: none;
  }

  .mg-top-130 {
    margin-top: 60px;
  }

  .main-title {
    text-align: center;
  }
  .main-title h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .hero {
    padding: 120px 0;
  }
  .hero-content {
    text-align: center;
  }
  .hero-content .img-mobile {
    display: block;
    max-width: 70%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .hero-buttons {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .hero-buttons .button {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
  }
  .hero-img {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .hero-img img {
    max-width: 70%;
  }
  .hero-img.mobile-hidden {
    display: none;
  }

  .dchf-page-hero {
    padding: 120px 0 0;
  }
  .dchf-page-hero-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .dchf-page-hero-content {
    text-align: center;
    padding-top: 0px;
  }
  .dchf-page-hero-content h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .dchf-page-hero-buttons {
    text-align: center;
    margin-top: 30px;
  }
  .dchf-page-hero-buttons .button {
    width: 300px;
    max-width: 100%;
  }
  .dchf-page-hero-img {
    text-align: center;
    margin-bottom: 50px;
  }
  .dchf-page-hero-img img {
    max-width: 70%;
  }

  .dchf-stats-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dchf-stats-item {
    text-align: center;
  }

  .dchf-about-img {
    text-align: center;
    margin-top: 30px;
  }
  .dchf-about-img img {
    max-width: 70%;
  }
  .dchf-about-content {
    text-align: center;
  }
  .dchf-about-content h1 {
    font-size: 26px;
  }
  .dchf-about-content .button {
    width: 300px;
    max-width: 100%;
    margin-top: 30px;
  }
  .dchf-about-row-mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .dchf-impression-wrap .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .dchf-for-content {
    text-align: center;
  }
  .dchf-for-content h1 {
    font-size: 26px;
  }

  .dchf-works .mg-top-50 {
    margin-top: 20px;
  }
  .dchf-works-row-mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .dchf-works-content {
    text-align: center;
    margin-top: 30px;
  }
  .dchf-works-content h1 {
    font-size: 26px;
  }
  .dchf-works-img img {
    margin: 0 auto;
    max-width: 60%;
  }
  .dchf-works-item {
    margin-top: 30px;
  }

  .dchf-benefits-wrap .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    min-height: auto;
  }
  .dchf-benefits-wrap .item-wrap {
    text-align: center;
  }

  .dchf-about-stats-wrap::before {
    height: 100%;
    background-image: none;
    background-color: #bf1304;
  }
  .dchf-about-stats-items {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .dchf-about-stats-items-wrap {
    padding: 0px;
    margin-bottom: 40px;
  }
  .dchf-about-stats-items .item {
    text-align: center;
  }
  .dchf-about-stats-items .item p {
    font-size: 20px;
  }
  .dchf-about-stats-content {
    text-align: center;
  }
  .dchf-about-stats-img img {
    width: 250px;
  }

  .dchf-works-about-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .dchf-works-about-img {
    text-align: center;
    margin-bottom: 30px;
  }
  .dchf-works-about-img img {
    max-width: 70%;
  }

  .dchf-use-case-title {
    margin-bottom: 0px;
  }
  .dchf-use-case .item {
    margin-top: 40px;
    height: auto;
  }

  .dchf-story-about {
    padding: 0px;
  }
  .dchf-story-about-img {
    text-align: center;
    margin-bottom: 50px;
  }
  .dchf-story-about-img img {
    max-width: 70%;
  }

  .dchf-works-about-content {
    text-align: center;
  }
  .dchf-works-about-content h1 {
    font-size: 26px;
  }
  .dchf-works-about-content p:first-of-type {
    margin-top: 30px;
  }

  .dchf-works-moneta-content {
    margin-bottom: 30px;
  }
  .dchf-works-moneta-content h1 {
    font-size: 26px;
  }
  .dchf-works-moneta-img {
    text-align: center;
  }
  .dchf-works-moneta-img img {
    max-width: 70%;
  }
  .dchf-works-moneta-button {
    margin-top: 30px;
  }

  .dchf-story-about-content {
    text-align: center;
  }
  .dchf-story-about-content h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .dchf-story-work-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .dchf-story-work-img {
    text-align: center;
    margin-bottom: 30px;
  }
  .dchf-story-work-img img {
    max-width: 70%;
  }
  .dchf-story-work-content {
    text-align: center;
  }
  .dchf-story-work-content h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .dchf-story-work-alt-wrap {
    padding: 40px 0;
  }
  .dchf-story-work-alt-wrap::before {
    background-image: none;
    background-color: #bf1304;
    height: 100%;
  }
  .dchf-story-work-alt-content {
    text-align: center;
    padding-top: 30px;
  }
  .dchf-story-work-alt-content h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .page-content {
    padding-top: 120px;
  }
  .page-content-text h1 {
    font-size: 26px;
  }

  .dchf-moneta-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .dchf-moneta-bg {
    margin-bottom: 30px;
  }

  .dchf-moneta-about-content h1 {
    font-size: 26px;
  }
  .dchf-moneta-about-wrap {
    height: auto;
  }
  .dchf-moneta-about-wrap .item.first {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .dchf-moneta-about-wrap .item.second {
    position: relative;
    right: 0;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .dchf-moneta-about-wrap .item.third {
    position: relative;
    left: 0;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .dchf-moneta-about-wrap .item.fourth {
    position: relative;
    right: 0;
    margin: 0 auto;
  }
  .dchf-moneta-about-wrap .grid {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    z-index: -1;
  }
  .dchf-moneta-about-wrap .grid img {
    height: 100%;
    width: 100%;
  }
  .dchf-moneta-about-wrap-alt {
    height: auto;
  }
  .dchf-moneta-about-wrap-alt .item.first {
    position: relative;
    left: 0;
    top: 0;
    margin: 0 auto;
    margin-bottom: 120px;
  }
  .dchf-moneta-about-wrap-alt .item.second {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    margin-bottom: 120px;
  }
  .dchf-moneta-about-wrap-alt .item.third {
    position: relative;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    margin-bottom: 120px;
  }
  .dchf-moneta-about-wrap-alt .item.fourth {
    position: relative;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .dchf-moneta-about-wrap-alt .grid {
    display: none;
  }

  .dchf-moneta-services .item {
    height: auto;
    margin-bottom: 30px;
  }

  .dchf-moneta-card-content {
    text-align: center;
    margin-top: 30px;
  }
  .dchf-moneta-card-content ul li {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-top {
    padding: 30px 0;
  }
  .footer-center {
    padding: 40px 0 0;
  }
  .footer-bottom-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }
  .footer-bottom-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer .dchf-newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-menu {
    margin-bottom: 50px;
  }
  .footer-logo {
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px;
    margin-bottom: 30px;
  }
}
