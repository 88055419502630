/*
    Name: DCHF
    Version: 1.0
    Author: Apper
    Author URI: http://apper.ch/
*/

@import "~bootstrap/scss/bootstrap";

@import "./styles/fonts";
@import "./styles/main";

@function getValue($map, $key) {
  @if map-has-key($map, $key) {
      @return map-get($map, $key);
  } @else {
      @error "ERROR: Specified index does not exist in the mapping";
  }   
}

/* Base Styles
-------------------------------------------------------------- */
html, body {
  font-family: #{getValue($fontList, 'regular')}, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  margin: 0;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  background-color: #FAFAFA;
}

::-moz-selection {
  background: #BF1304;
  color: #FFFFFF;
}

::selection {
  background: #BF1304;
  color: #FFFFFF;
}

::-moz-selection {
  background: #BF1304;
  color: #FFFFFF;
}

.app-loading {
  min-height: 100vh;
}
