$fontList: (
  primary: 'MenaGrotesk-Black',
  primary-italic: 'MenaGrotesk-BlackItalic',
  bold: 'MenaGrotesk-Bold',
  bold-italic: 'MenaGrotesk-BoldItalic',
  light: 'MenaGrotesk-Light',
  light-italic: 'MenaGrotesk-LightItalic',
  medium: 'MenaGrotesk-Medium',
  medium-italic: 'MenaGrotesk-MediumItalic',
  regular: 'MenaGrotesk-Regular',
  regular-italic: 'MenaGrotesk-RegularItalic',
  semibold: 'MenaGrotesk-SemiBold',
  semibold-italic: 'MenaGrotesk-SemiBoldItalic',
  thin: 'MenaGrotesk-Thin',
  thin-italic: 'MenaGrotesk-ThinItalic',
  ultrabold: 'MenaGrotesk-UltraBold',
  ultrabold-italic: 'MenaGrotesk-UltraBoldItalic',
  ultralight: 'MenaGrotesk-UltraLight',
  ultralight-italic: 'MenaGrotesk-UltraLightItalic'
);

@each $fontKey, $fontFamily in $fontList {
  @font-face {
    font-display: swap;
    font-family: $fontFamily;
    src: URL('/public/fonts/#{$fontFamily}.eot');
    src: URL('/public/fonts/#{$fontFamily}.eot?#iefix') format('embedded-opentype'),
         URL('/public/fonts/#{$fontFamily}.woff2') format('woff2'), 
         URL('/public/fonts/#{$fontFamily}.woff') format('woff'),
         URL('/public/fonts/#{$fontFamily}.ttf') format('truetype');
  }

  .font-#{$fontKey} {
    font-family: $fontFamily;
  }
}

// $fsizes: 10, 12, 14, 16, 18, 20, 22, 24, 26, 32, 40, 56;

// // Generate different font sizes
// @each $size in $fsizes {
//   .f-#{$size} {
//     font-size: #{$size}px;
//   }
// }

// // Generate different line height
// @each $size in $fsizes {
//   .ln-#{$size} {
//     line-height: #{$size}px;
//   }
// }

// // Generate different font sizes
// @each $size in $fsizes {
//   .md-#{$size} {
//     font-size: #{$size}px;
//     width: #{$size}px !important;
//     height: #{$size}px !important;
//   }
// }

// .vmd {
//   vertical-align: middle !important;
// }

// .vbt {
//   vertical-align: bottom !important;
// }

// .vsb {
//   vertical-align: sub !important;
// }

// .vtt {
//   vertical-align: text-top;
// }

.upcase {
  text-transform: uppercase;
}